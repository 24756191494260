@import './colors';
form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 10em;
    font-size: 1.3em;
    label {
        width: 100%;
        text-align: center;
        input {
            width: 100%;
            border: none;
            font-size: 1em;
            border-width: 0 0 2px;
            border-style: solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(to left, $white, $black, $white); 
            text-align: center;
        }
        input:focus {
            outline: none;
            border-image-source: linear-gradient(to left, $white, $skyBlue, $white);
        }
        .addPriorityInput {
            width: 50%;
        }
        .editDescriptionInput {
            width: 80%;
        }
    }
    button {
        margin-left: 1.5em;
        border: none;
        background:black;
        color: $white;
        border-radius: 0.5em;
        padding: 0.25em 0.5em;
        font-size: 0.9em;
    }
    button:focus {
        outline: none;
        box-shadow: 0 0 0 3px $skyBlue;
    }
}