@import './colors';
.Todo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    padding: 0.5em 0;
    position: relative;
    font-size: 1.2em;
    cursor: move;
    .todoContent {
        min-height: 2.5em;
        min-width: 15em;
        width: 100%;
        .todoPriority {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2em;
            right: 0;
            top: 0.7em;
            color: $black;
            border-radius: 50%;
            width: 1.25em;
            height: 1.25em;
            text-align: center;
            border: solid 1px $black;
        }
        
        .priority1 {
            color: $priority1;
            background: $black;
        }
        .priority2 {
            background: $priority2;
        }
        .priority3 {
            background: $priority3;
        }
        .priority4 {
            background: $priority4;
        }
        .priority5 {
            background: $priority5;
        }
        .todoText {
            display: inline-block;
            width: 85%;
        }
    }
}
.marked {
    color: $gray;
    .todoText {
        text-decoration: line-through;
    }
    
}